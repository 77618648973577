<template>
  <div class="place-card-on-map">
    <Column class="place-card-on-map__wrapper">
      <Row justify="between">
        <Column class="place-card-on-map__meta">
          <Row v-if="params && params.tags && params.tags.length > 0"
               class="place-card-on-map__badges -m-h-5"
               wrap>
            <Badge v-for="badge in params.tags"
                   :key="`badge-place-card-on-map-${badge.id}`"
                   :params="{text: badge.name, colors: {
                       text: '#ffffff', background: badge.color}}"/>
          </Row>
          <span v-if="params && params.title"
                class="place-card-on-map__title">{{params.title}}</span>
          <span v-if="params && params.address"
                class="place-card-on-map__address">{{params.address}}</span>
        </Column>
        <Column>
          <img class="place-card-on-map__image"
               :src="params.images[0]"
               alt/>
        </Column>
      </Row>
      <Row class="place-card-on-map__additions"
           justify="between">
        <div>
          <Rating :params="{id: params.id,
                            component: 'Column',
                            align: 'start',
                            type: 'trips',
                            rating: params.rating,
                            currentUserRating: params.currentUserRating}"/>
        </div>
        <Column justify="end">
          <span v-on:click.prevent="toLink(`${$locale.base}${params.link}`)">)">
            {{ $t('service.details') }}</span>
        </Column>
        <Column>
          <Favorite :params="{id, entity, favorited}"/>
          <span>{{ $t('service.bookmark') }}</span>
        </Column>
      </Row>
    </Column>
  </div>
</template>

<script>
export default {
  name: 'PlaceCardOnMap',
  props: {
    params: {},
  },
  methods: {
    toLink(link) {
      this.$router.push(link);
    },
  },
  computed: {
    id() {
      return this?.params?.id;
    },
    entity() {
      return this?.params?.entity;
    },
    favorited() {
      return this?.params?.favorited;
    },
  },
};
</script>
