var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"place-card-on-map"},[_c('Column',{staticClass:"place-card-on-map__wrapper"},[_c('Row',{attrs:{"justify":"between"}},[_c('Column',{staticClass:"place-card-on-map__meta"},[(_vm.params && _vm.params.tags && _vm.params.tags.length > 0)?_c('Row',{staticClass:"place-card-on-map__badges -m-h-5",attrs:{"wrap":""}},_vm._l((_vm.params.tags),function(badge){return _c('Badge',{key:`badge-place-card-on-map-${badge.id}`,attrs:{"params":{text: badge.name, colors: {
                     text: '#ffffff', background: badge.color}}}})}),1):_vm._e(),(_vm.params && _vm.params.title)?_c('span',{staticClass:"place-card-on-map__title"},[_vm._v(_vm._s(_vm.params.title))]):_vm._e(),(_vm.params && _vm.params.address)?_c('span',{staticClass:"place-card-on-map__address"},[_vm._v(_vm._s(_vm.params.address))]):_vm._e()],1),_c('Column',[_c('img',{staticClass:"place-card-on-map__image",attrs:{"src":_vm.params.images[0],"alt":""}})])],1),_c('Row',{staticClass:"place-card-on-map__additions",attrs:{"justify":"between"}},[_c('div',[_c('Rating',{attrs:{"params":{id: _vm.params.id,
                          component: 'Column',
                          align: 'start',
                          type: 'trips',
                          rating: _vm.params.rating,
                          currentUserRating: _vm.params.currentUserRating}}})],1),_c('Column',{attrs:{"justify":"end"}},[_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.toLink(`${_vm.$locale.base}${_vm.params.link}`)}}},[_vm._v(")\"> "+_vm._s(_vm.$t('service.details')))])]),_c('Column',[_c('Favorite',{attrs:{"params":{id: _vm.id, entity: _vm.entity, favorited: _vm.favorited}}}),_c('span',[_vm._v(_vm._s(_vm.$t('service.bookmark')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }